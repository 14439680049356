import HtmlIcon from "@/../../public/assets/html-icon.svg";
import CSSIcon from "@/../../public/assets/css-icon.svg";
import JsIcon from "@/../../public/assets/js-icon.svg";
import JQueryIcon from "@/../../public/assets/jq-icon.svg";
import WorkExp1 from "@/../../public/assets/workExp1.png";
import WorkExp2 from "@/../../public/assets/workExp2.png";
import WorkExp3 from "@/../../public/assets/workExp3.png";
import resumeIcon from "@/../../public/assets/resumeIcon.png";
import Post1 from "@/../../public/assets/f1.jpg";
import Post2 from "@/../../public/assets/f2.jpg";
import Post3 from "@/../../public/assets/f3.jpg";
import heart from "@/../../public/assets/heart.svg";
import comment from "@/../../public/assets/comment.svg";
import share from "@/../../public/assets/share.svg";
import JobIcon1 from "@/../../public/assets/job-icon-1.png";
import JobIcon2 from "@/../../public/assets/job-icon-1.png";

//Skills related data
export const skills = [
  { link: HtmlIcon, title: "HTML" },
  { link: CSSIcon, title: "CSS" },
  { link: JsIcon, title: "JS" },
  { link: JQueryIcon, title: "jQuery" },
];

//experience related data

export const experience = [
  {
    link: WorkExp1,
    designation: "Senior software developer",
    company: "XYZ company • Full time",
    timeLine: "Oct 2019 - Present • 3 years 2 mo",
  },
  {
    link: WorkExp2,
    designation: "Senior software developer",
    company: "XYZ company • Full time",
    timeLine: "Oct 2019 - Present • 3 years 2 mo",
  },
  {
    link: WorkExp3,
    designation: "Senior software developer",
    company: "XYZ company • Full time",
    timeLine: "Oct 2019 - Present • 3 years 2 mo",
  },
];

//Resume related data
export const resume = [
  {
    link: resumeIcon,
    docName: "Portfolio - Sean Doe ",
    docSize: "PDF file • 15MB",
  },
];

// Education related data
export const education = [{ degree: "Bachelor’s degree" }, { degree: "Master degree" }, { degree: "PHD" }];

//Certificates related data
export const certificates = [
  {
    link: resumeIcon,
    docName: "Education Certificate 1 ",
    docSize: "JPEG file • 10MB",
  },
  {
    link: resumeIcon,
    docName: "Education Certificate 2 ",
    docSize: "JPEG file • 10MB",
  },
  {
    link: resumeIcon,
    docName: "Education Certificate 3 ",
    docSize: "JPEG file • 10MB",
  },
];

//Posts related data

export const posts = [
  {
    mainImage: Post1,
    headingText: "Amanda Stone posted this • 3d",
    paraText:
      " Lorem ipsum dolor sit amet. Aut sunt officia aut nesciunt sunt estaperiam ullam eos inventore quaerat a dolorem sequi ea ipsam iure.At alias cupiditate est ipsa cumque in officia nisi",
    likeImage: heart,
    likeText: "1.3K likes",
    commentImage: comment,
    commentText: "200 comments",
    shareImage: share,
    shareText: "20K shares",
  },
  {
    mainImage: Post2,
    headingText: "Amanda Stone posted this • 3d",
    paraText:
      " Lorem ipsum dolor sit amet. Aut sunt officia aut nesciunt sunt estaperiam ullam eos inventore quaerat a dolorem sequi ea ipsam iure.At alias cupiditate est ipsa cumque in officia nisi",
    likeImage: heart,
    likeText: "1.3K likes",
    commentImage: comment,
    commentText: "200 comments",
    shareImage: share,
    shareText: "20K shares",
  },
  {
    mainImage: Post3,
    headingText: "Amanda Stone posted this • 3d",
    paraText:
      " Lorem ipsum dolor sit amet. Aut sunt officia aut nesciunt sunt estaperiam ullam eos inventore quaerat a dolorem sequi ea ipsam iure.At alias cupiditate est ipsa cumque in officia nisi",
    likeImage: heart,
    likeText: "1.3K likes",
    commentImage: comment,
    commentText: "200 comments",
    shareImage: share,
    shareText: "20K shares",
  },
  {
    mainImage: Post2,
    headingText: "Amanda Stone posted this • 3d",
    paraText:
      " Lorem ipsum dolor sit amet. Aut sunt officia aut nesciunt sunt estaperiam ullam eos inventore quaerat a dolorem sequi ea ipsam iure.At alias cupiditate est ipsa cumque in officia nisi",
    likeImage: heart,
    likeText: "1.3K likes",
    commentImage: comment,
    commentText: "200 comments",
    shareImage: share,
    shareText: "20K shares",
  },
  {
    mainImage: Post1,
    headingText: "Amanda Stone posted this • 3d",
    paraText:
      " Lorem ipsum dolor sit amet. Aut sunt officia aut nesciunt sunt estaperiam ullam eos inventore quaerat a dolorem sequi ea ipsam iure.At alias cupiditate est ipsa cumque in officia nisi",
    likeImage: heart,
    likeText: "1.3K likes",
    commentImage: comment,
    commentText: "200 comments",
    shareImage: share,
    shareText: "20K shares",
  },
];

//Upcoming interviews related data
export const upcomingInterviews = [
  {
    jobIcon: JobIcon1,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: true,
    closeIcon: false,
  },
  {
    jobIcon: JobIcon2,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: true,
    closeIcon: false,
  },
];

//Completed interviews related data
export const completedInterviews = [
  {
    jobIcon: JobIcon1,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: false,
    closeIcon: true,
  },
  {
    jobIcon: JobIcon2,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: false,
    closeIcon: true,
  },
];

// New Interviews Relataed data
export const newInterviews = [
  {
    jobIcon: JobIcon1,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    interViewLength: "1 hour ",
    timeRange: "Within 2 days",
    experience: "1-2 Year",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: true,
    closeIcon: false,
  },
  {
    jobIcon: JobIcon2,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    interViewLength: "1 hour ",
    timeRange: "Within 2 days",
    experience: "1-2 Year",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: true,
    closeIcon: false,
  },
];

//Comapny open and close roles related data
export const openRolesForCompany = [
  {
    jobIcon: JobIcon1,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: false,
    closeIcon: true,
  },
  {
    jobIcon: JobIcon2,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: false,
    closeIcon: true,
  },
];
export const closedRolesForCompany = [
  {
    jobIcon: JobIcon1,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: false,
    closeIcon: true,
  },
  {
    jobIcon: JobIcon2,
    designation: "Senior Frontend Developer",
    status: "(Scheduled)",
    company: "Lake Lonny Pvt. Ltd.",
    postTime: "28 Mar 2023",
    jobType: "Full Time",
    experience: "1-2 Year",
    location: "NY,USA",
    salary: "$30k-40k",
    description:
      "Lorem ipsum dolor sit amet. Hic doloribus temporibus etdeleniti adipisci ut earum porro. Qui odio praesentium utincidunt molestias aut distinctio quis ex velit optio cumnobis lorem ipsum",
    showButtons: false,
    closeIcon: true,
  },
];
//Users Section related data
export const compAndRecruit = {
  headText: "cms_text_landing_userSection_compAndRecruit_headText",
  paraText: "cms_text_landing_userSection_compAndRecruit_paraText",
};
export const interviewers = {
  headText: "cms_text_landing_userSection_interviewers_headText",
  paraText: "cms_text_landing_userSection_interviewers_paraText",
};
export const jobSeekers = {
  headText: "cms_text_landing_userSection_jobSeekers_headText",
  paraText: "cms_text_landing_userSection_jobSeekers_paraText",
};

//month dropdown

export function generateMonthArray() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthObjects = months.map((month, index) => {
    return { title: month, value: (index + 1).toString() };
  });

  return monthObjects;
}

//year dropdowns
export function generateYearArray() {
  const startYear_school = 1994;
  const endYear_school = new Date().getFullYear();

  const years = [];
  for (let year = startYear_school; year <= endYear_school; year++) {
    years.push({ title: String(year), value: String(year) });
  }

  return years;
}
export function generateYearArrayForCertifications() {
  const startYear_school = 1994;
  const endYear = 3000;

  const years = [];
  for (let year = startYear_school; year <= endYear; year++) {
    years.push({ title: String(year), value: String(year) });
  }

  return years;
}

//Multiple selection dropdowns

export const skillsData = [
  { title: "CSS", value: "CSS" },
  { title: "HTML", value: "HTML" },
  { title: "JS", value: "JS" },
  { title: "PHP", value: "PHP" },
];

// Landing page application usage
export const applicationUsage = [
  {
    id: 1,
    content: "cms_text_landing_applicationUsage_content_001",
    title: "cms_text_landing_applicationUsage_title_001",
    color: "gren",
  },
  {
    id: 2,
    content: "cms_text_landing_applicationUsage_content_002",
    title: "cms_text_landing_applicationUsage_title_002",
    color: "oreng",
  },
  {
    id: 3,
    content: "cms_text_landing_applicationUsage_content_003",
    title: "cms_text_landing_applicationUsage_title_003",
    color: "parpel",
  },
  {
    id: 4,
    content: "cms_text_landing_applicationUsage_content_004",
    title: "cms_text_landing_applicationUsage_title_004",
    color: "blue",
  },
  {
    id: 5,
    content: "cms_text_landing_applicationUsage_content_005",
    title: "cms_text_landing_applicationUsage_title_005",
    color: "oreng",
  },
  {
    id: 6,
    content: "cms_text_landing_applicationUsage_content_006",
    title: "cms_text_landing_applicationUsage_title_006",
    color: "blue",
  },
  {
    id: 7,
    content: "cms_text_landing_applicationUsage_content_007",
    title: "cms_text_landing_applicationUsage_title_007",
    color: "gren",
  },
];

export const pronounsData = [
  { title: "She/Her", value: "She/Her" },
  { title: "He/Him", value: "He/Him" },
  { title: "They/Them", value: "They/Them" },
  { title: "Custom", value: "Custom" },
  { title: "None", value: "None" },
];
//Employement Type Data

export const employementData = [
  { title: "Full time", value: "Full time" },
  { title: "Part time", value: "Part time" },
  { title: "Contract", value: "Contract" },
  { title: "Internship", value: "Internship" },
  { title: "Volunteer", value: "Volunteer" },
  { title: "Temporary", value: "Temporary" },
  { title: "Other", value: "Other" },
];
export const employementDataExceptOther = [
  { title: "Full time", value: "Full time" },
  { title: "Part time", value: "Part time" },
  { title: "Contract", value: "Contract" },
  { title: "Internship", value: "Internship" },
  { title: "Volunteer", value: "Volunteer" },
  { title: "Temporary", value: "Temporary" },
];
const today = new Date();
export const defaultDOB = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate());
//Post job data

export const salaryData = [
  { title: "$200-$300", value: { salaryStart: "200", salaryEnd: "300" } },
  { title: "$300-$1000", value: { salaryStart: "300", salaryEnd: "1000" } },
];
export const jobModel = [
  { title: "On Site", value: "Onsite" },
  { title: "Hybrid", value: "Hybrid" },
  { title: "Remote", value: "Remote" },
];
export const salaryTypeData = [
  { title: "Per Year", value: "Per Year" },
  { title: "Per Hour", value: "Per Hour" },
  { title: "Per Month", value: "Per Month" },
  { title: "Per Week", value: "Per Week" },
];
// export const percentageMatchData = [{ title: "Any Match", value: "Any Match" }, { title: "More than 25%", value: "More than 25%" }, { title: "More than 50%", value: "More than 50%" }, { title: "More than 75%", value: "More than 75%" }, { title: "100% Match", value: "100% Match" }]
export const experienceJobPost = [
  { title: "No Experience Needed", value: "No Experience Needed" },
  { title: "Under 1 Year", value: "Under 1 Year" },
  { title: "1- 3 Years", value: "1- 3 Years" },
  { title: "3-5 Years", value: "3-5 Years" },
  { title: "5-7 Years", value: "5-7 Years" },
  { title: "7-10 Years", value: "7-10 Years" },
  { title: "10-13 Years", value: "10-13 Years" },
  { title: "Other", value: "Other" },
];
export const experienceJobPostExceptOther = [
  { title: "No Experience Needed", value: "No Experience Needed" },
  { title: "Under 1 Year", value: "Under 1 Year" },
  { title: "1- 3 Years", value: "1- 3 Years" },
  { title: "3-5 Years", value: "3-5 Years" },
  { title: "5-7 Years", value: "5-7 Years" },
  { title: "7-10 Years", value: "7-10 Years" },
  { title: "10-13 Years", value: "10-13 Years" },
];
export const defaultDeadline = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
export const defaultDeadlineFuture = new Date(today.getFullYear() + 5, today.getMonth(), today.getDate());
export const jobTypeData = [
  { title: "Full Time", value: "Full Time" },
  { title: "Part Time", value: "Part Time" },
  { title: "Contract", value: "Contract" },
  { title: "Temporary", value: "Temporary" },
  { title: "Internship", value: "Internship" },
];
export const employeeSizeData = [
  { title: "1-10 Employees", value: "1-10" },
  { title: "10-100 Employees", value: "10-100" },
  { title: "100 - 500 Employees", value: "100-500" },
  { title: "500 - 1000 Employees", value: "500-1000" },
  { title: "1000 - 5000 Employees", value: "1000-5000" },
  { title: "5000+ Employees", value: "5000+" },
];
//Industry names for company signup
export const namesOfIndustry = [
  { title: "Aerospace", value: "Aerospace" },
  { title: "Agriculture", value: "Agriculture" },
  { title: "Automotive", value: "Automotive" },
  { title: "Basic Metal", value: "Basic Metal" },
  { title: "Chemical", value: "Chemical" },
  { title: "Computer", value: "Computer" },
  { title: "Construction", value: "Construction" },
  { title: "Consulting Services", value: "Consulting Services" },
  { title: "Creative", value: "Creative" },
  { title: "Cultural", value: "Cultural" },
  { title: "Defense", value: "Defense" },
  { title: "Education", value: "Education" },
  { title: "Electric Power", value: "Electric Power" },
  { title: "Electronics", value: "Electronics" },
  { title: "Energy", value: "Energy" },
  { title: "Engineering", value: "Engineering" },
  { title: "Entertainment", value: "Entertainment" },
  { title: "Event Planning", value: "Event Planning" },
  { title: "Farming", value: "Farming" },
  { title: "Fashion", value: "Fashion" },
  { title: "Film", value: "Film" },
  { title: "Financial Services", value: "Financial Services" },
  { title: "Fishing", value: "Fishing" },
  { title: "Food", value: "Food" },
  { title: "Forestry", value: "Forestry" },
  { title: "Gambling", value: "Gambling" },
  { title: "Gaming", value: "Gaming" },
  { title: "Gas", value: "Gas" },
  { title: "Green Industry", value: "Green Industry" },
  { title: "Health Services", value: "Health Services" },
  { title: "Hospitality", value: "Hospitality" },
  { title: "Hotels", value: "Hotels" },
  { title: "Industrial Robot", value: "Industrial Robot" },
  { title: "Information", value: "Information" },
  { title: "Information Technology", value: "Information Technology" },
  { title: "Infrastructure", value: "Infrastructure" },
  { title: "Insurance", value: "Insurance" },
  { title: "Law", value: "Law" },
  { title: "Leisure", value: "Leisure" },
  { title: "Low Technology", value: "Low Technology" },
  { title: "Manufacturing", value: "Manufacturing" },
  { title: "Meat", value: "Meat" },
  { title: "Media", value: "Media" },
  { title: "Merchandising", value: "Merchandising" },
  { title: "Mining", value: "Mining" },
  { title: "Music", value: "Music" },
  { title: "News Media", value: "News Media" },
  { title: "Oil and Gas", value: "Oil and Gas" },
  { title: "Pharmaceutical", value: "Pharmaceutical" },
  { title: "Professional Services", value: "Professional Services" },
  { title: "Publishing", value: "Publishing" },
  { title: "Pulp and Paper", value: "Pulp and Paper" },
  { title: "Railway", value: "Railway" },
  { title: "Real Estate", value: "Real Estate" },
  { title: "Retail", value: "Retail" },
  { title: "Scientific", value: "Scientific" },
  { title: "Services", value: "Services" },
  { title: "Software", value: "Software" },
  { title: "Space", value: "Space" },
  { title: "Sport", value: "Sport" },
  { title: "Steel", value: "Steel" },
  { title: "Technology", value: "Technology" },
  { title: "Telecommunications", value: "Telecommunications" },
  { title: "Textile", value: "Textile" },
  { title: "Tobacco", value: "Tobacco" },
  { title: "Transport", value: "Transport" },
  { title: "Utility", value: "Utility" },
  { title: "Video Game", value: "Video Game" },
  { title: "Water", value: "Water" },
  { title: "Wholesale", value: "Wholesale" },
  { title: "Wood", value: "Wood" },
];

//Degree in edit profile as default role
export const degreesInEditProfile = [
  { title: "Pre-Associate Degree", value: "Pre-Associate Degree" },
  { title: "Associate Degree", value: "Associate Degree" },
  { title: "Bachelor's Degree", value: "Bachelor's Degree" },
  { title: "Master's Degree", value: "Master's Degree" },
  { title: "Doctoral Degree", value: "Doctoral Degree" },
];

export const workAuthorization = [
  { title: "U.S. Citizen", value: "U.S. Citizen" },
  { title: "EAD", value: "EAD" },
  { title: "Green Card", value: "Green Card" },
  { title: "F-1 Visa (OPT)", value: "F-1 Visa(OPT)" },
  { title: "F-1 Visa (CPT)", value: "F-1 Visa(CPT)" },
  { title: "H-1B Visa", value: "H-1B Visa" },
  { title: "L-1 Visa", value: "L-1 Visa" },
  { title: "O-1 Visa", value: "O-1 Visa" },
  { title: "Other", value: "Other" },
];

export const forbiddenDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "yahoo.co.uk",
  "yahoo.com.br",
  "yahoo.co.in",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net",
  "sfr.fr",
  "live.fr",
  "verizon.net",
  "live.co.uk",
  "googlemail.com",
  "yahoo.es",
  "ig.com.br",
  "live.nl",
  "bigpond.com",
  "terra.com.br",
  "yahoo.it",
  "neuf.fr",
  "yahoo.de",
  "alice.it",
  "rocketmail.com",
  "att.net",
  "laposte.net",
  "facebook.com",
  "bellsouth.net",
  "yahoo.in",
  "hotmail.es",
  "charter.net",
  "yahoo.ca",
  "yahoo.com.au",
  "rambler.ru",
  "hotmail.de",
  "tiscali.it",
  "shaw.ca0.1%",
  "yahoo.co.jp",
  "sky.com0.1%",
  "earthlink.net",
  "optonline.net",
  "freenet.de",
  "t-online.de",
  "aliceadsl.fr",
  "virgilio.it",
  "home.nl",
  "qq.com",
  "telenet.be",
  "me.com",
  "yahoo.com.ar",
  "tiscali.co.uk",
  "yahoo.com.mx",
  "voila.fr",
  "gmx.net",
  "mail.com",
  "planet.nl",
  "tin.it",
  "live.it",
  "ntlworld.com",
  "arcor.de",
  "yahoo.co.id",
  "frontiernet.net",
  "hetnet.nl",
  "live.com.au",
  "yahoo.com.sg",
  "zonnet.nl",
  "club-internet.fr",
  "juno.com",
  "optusnet.com.au",
  "blueyonder.co.uk",
  "bluewin.ch",
  "skynet.be",
  "sympatico.ca",
  "windstream.net",
  "mac.com",
  "centurytel.net",
  "chello.nl",
  "live.ca",
  "aim.com",
  "bigpond.net.au",
];

//Review-Applicants Page
//Applied candidates Sponshirship Dropdown
export const sponshirshipData = [
  { title: "Required Sponsorship", value: "Required Sponsorship" },
  { title: "Not Required Sponsorship", value: "Not Required Sponsorship" },
  {
    title: "Required Sponsorship in Future",
    value: "Required Sponsorship in Future",
  },
];
export const sponshirshipDataWithoutAny = [
  { title: "Required Sponsorship", value: "Required Sponsorship" },
  { title: "Not Required Sponsorship", value: "Not Required Sponsorship" },
  {
    title: "Required Sponsorship in Future",
    value: "Required Sponsorship in Future",
  },
];

export const rolesForComapny = [
  {
    title: "Company Owner",
    value: "Company Owner",
  },
];
export const rolesForComapnyInvite = [
  {
    title: "Company Admin",
    value: "Company Admin",
  },
  {
    title: "Company HR",
    value: "Company HR",
  },
];

// Close reasons for job poster to close the job

export const reasonToClose = [
  {
    title: "Position Filled (External Candidate Hired)",
    value: "Position Filled (External Candidate Hired)",
  },
  {
    title: "Position Filled (Internal Candidate Hired)",
    value: "Position Filled (Internal Candidate Hired)",
  },
  {
    title: "Job Canceled (Budget Constraints)",
    value: "Job Canceled (Budget Constraints)",
  },
  {
    title: "Job Canceled (Change in Hiring Needs)",
    value: "Job Canceled (Change in Hiring Needs)",
  },
  {
    title: "Job Canceled (Organizational Changes)",
    value: "Job Canceled (Organizational Changes)",
  },
  {
    title: "No Suitable Candidates",
    value: "No Suitable Candidates",
  },
  {
    title: "Requisition On Hold (Temporary Hiring Freeze)",
    value: "Requisition On Hold (Temporary Hiring Freeze)",
  },
  {
    title: "Requisition On Hold (Pending Approval)",
    value: "Requisition On Hold (Pending Approval)",
  },
  {
    title: "Merged with Another Requisition",
    value: "Merged with Another Requisition",
  },
  {
    title: "Duplicate Job Posting",
    value: "Duplicate Job Posting",
  },
];

//Interview Details form

export const interviewDurationData = [
  {
    title: "30 min",
    value: "30 min",
  },
  {
    title: "45 min",
    value: "45 min",
  },
  {
    title: "1 hr",
    value: "1 hr",
  },
  {
    title: "1 hr 30 min",
    value: "1 hr 30 min",
  },
  {
    title: "2 hr",
    value: "2 hr",
  },
];

export const interviewDeadlineData = [
  {
    title: "7 days",
    value: "7 days",
  },
  {
    title: "14 days",
    value: "14 days",
  },
];

//Payment section
export const paymentSectionData = [
  {
    title: "India",
    value: "India",
  },
  {
    title: "USA",
    value: "USA",
  },
];

//Reason for walkout for interviewer

export const interviewerWalkoutData = [
  {
    title: "Scheduling Conflict",
    value: "Scheduling Conflict",
  },
  {
    title: "Personal Emergency",
    value: "Personal Emergency",
  },
  {
    title: "Technical Issues",
    value: "Technical Issues",
  },
  {
    title: "Mismatch In Expectations",
    value: "Mismatch In Expectations",
  },
  {
    title: "Uncomfortable Environment",
    value: "Uncomfortable Environment",
  },
  {
    title: "Inadequate Preparation",
    value: "Inadequate Preparation",
  },
  {
    title: "Unprofessional Behavior",
    value: "Unprofessional Behavior",
  },
  {
    title: "Insufficient Information Provided",
    value: "Insufficient Information Provided",
  },
  {
    title: "Severe Climate Conditions",
    value: "Severe Climate Conditions",
  },
  {
    title: "Other (Please Specify)",
    value: "Other (Please Specify)",
  },
];

export const interviewerWalkoutDataExceptOther = [
  {
    title: "Scheduling Conflict",
    value: "Scheduling Conflict",
  },
  {
    title: "Personal Emergency",
    value: "Personal Emergency",
  },
  {
    title: "Technical Issues",
    value: "Technical Issues",
  },
  {
    title: "Mismatch In Expectations",
    value: "Mismatch In Expectations",
  },
  {
    title: "Uncomfortable Environment",
    value: "Uncomfortable Environment",
  },
  {
    title: "Inadequate Preparation",
    value: "Inadequate Preparation",
  },
  {
    title: "Unprofessional Behavior",
    value: "Unprofessional Behavior",
  },
  {
    title: "Insufficient Information Provided",
    value: "Insufficient Information Provided",
  },
  {
    title: "Severe Climate Conditions",
    value: "Severe Climate Conditions",
  },
];

//Interview schedule interview time
export const interviewScheduleTimeData = [
  {
    title: "Full Time",
    value: "Full Time",
  },
  {
    title: "Part Time",
    value: "Part Time",
  },
];

export const jsonData = {
  action: "sendmessage",
};
